import React from "react";

interface Props {
  exercise: CourseExercise;
}

// const imagesBaseUrl = "http://localhost:3300/images";

const imagesBaseUrl = "https://api.dialogino.cz/images";

export default function VocabularyExerciseData(props: Props) {
  const { exercise } = props;

  const imageName = exercise.vocabularyExerciseData?.imageFileName ?? "";
  const exerciseAnswer = exercise.vocabularyExerciseData?.correctAnswers ?? "";
  const exerciseWrongAnswers =
    exercise.vocabularyExerciseData?.wrongAnswers ?? "";

  return (
    <div className="flex items-center">
      <div>
        <img
          src={`${imagesBaseUrl}/${imageName}.svg`}
          alt={imageName}
          height={15}
          width={15}
        />
      </div>
      <div className="text-slate-300 text-xs pl-3">{exerciseAnswer}</div>
      <div className="text-red-300 text-xs pl-3">{exerciseWrongAnswers}</div>
    </div>
  );
}
