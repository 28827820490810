import React, { useCallback } from "react";

import { Container, FormControl, TextField } from "@mui/material";

interface Props {
  exerciseData?: VocabularyPairsExerciseData;
  onChange: (value: VocabularyPairsExerciseData) => void;
}
export default function VocabularyPairsExerciseForm(props: Props) {
  const { exerciseData, onChange } = props;

  const setWords = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        words: value,
      });
    },
    [exerciseData, onChange],
  );

  const setTranslations = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        translations: value,
      });
    },
    [exerciseData, onChange],
  );

  return (
    <Container>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Palabras"
          placeholder="La flor, El perro, El gato, La mesa"
          variant="outlined"
          value={exerciseData?.words}
          onChange={(e) => setWords(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Traducciones"
          placeholder="Květina, Pes, Kočka, Stůl"
          variant="outlined"
          value={exerciseData?.translations}
          onChange={(e) => setTranslations(e.target.value)}
        />
      </FormControl>
    </Container>
  );
}
