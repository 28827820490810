/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useCallback, useState } from "react";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";

import LessonNotesConfiguration from "./components/lesson-notes-configuration";
import client from "../../../../api/api";

const imagesBaseUrl = "https://api.dialogino.cz/lesson_icons";

interface Props {
  unitId: string;
  lesson?: CourseLesson;
  onClose: () => void;
}

export default function AddEditLessonDialog(props: Props) {
  const { unitId, onClose, lesson } = props;
  const [name, setName] = useState<string>(lesson?.name ?? "");
  const [iconFileName, setIconFileName] = useState<string>(
    lesson?.iconFileName ?? "",
  );
  const [lessonNotes, setLessonNotes] = useState<CoursenNote[]>(
    lesson?.lessonNotes ?? [],
  );

  const queryClient = useQueryClient();

  const createLesson = async () => {
    if (!unitId) throw new Error("Lesson id is not defined");
    if (lesson && lesson._id) {
      const response = await client.lessonsLessonIdPut(lesson._id, {
        unitId: unitId,
        name,
        lessonNotes: lessonNotes,
        iconFileName: iconFileName,
      });
      return response.data.result;
    } else {
      const response = await client.lessonsPost({
        unitId: unitId,
        name,
        lessonNotes: lessonNotes,
        iconFileName: iconFileName,
      });
      return response.data.result;
    }
  };

  const { mutate } = useMutation({
    mutationFn: createLesson,
    onSuccess: () => {
      queryClient.invalidateQueries("lessons");
      onClose();
    },
  });

  const onCreateExercise = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <Box>
      <DialogTitle variant="h4">
        {lesson ? "Editar lección" : "Nueva lección"}
      </DialogTitle>
      <DialogContent dividers sx={{ maxHeight: "70vh", overflow: "auto" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <TextField
              id="outlined-basic"
              label="Nombre"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <TextField
              id="outlined-basic"
              label="Icono"
              variant="outlined"
              value={iconFileName}
              onChange={(e) => setIconFileName(e.target.value)}
            />
          </FormControl>
          <Box
            width={50}
            height={50}
            sx={{ background: "grey" }}
            border={"solid 1px black"}
            borderRadius={25}
          >
            {iconFileName.length > 0 && (
              <img
                src={`${imagesBaseUrl}/${iconFileName}.svg`}
                alt={iconFileName}
              />
            )}
          </Box>
        </Box>
        <LessonNotesConfiguration
          notes={lessonNotes}
          onChange={setLessonNotes}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="error">
          cancelar
        </Button>
        <Button onClick={onCreateExercise}>Guardar</Button>
      </DialogActions>
    </Box>
  );
}
