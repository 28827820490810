import { useMemo } from "react";

import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import client from "../api/api";

export const useQuerys = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useExercisesQuery = (lessonId: string) => {
  return useQuery({
    enabled: true,
    queryKey: ["exercises", lessonId],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const response = await client.exercisesGet(lessonId);
      return response.data.content;
    },
  });
};
export const useLessonsQuery = (unitId: string) => {
  return useQuery({
    enabled: true,
    queryKey: ["lessons", unitId],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const response = await client.lessonsGet(unitId);
      return response.data.content;
    },
  });
};
export const useUnitsQuery = (sectionId: string) => {
  return useQuery({
    enabled: true,
    queryKey: ["units", sectionId],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const response = await client.unitsGet(sectionId);
      return response.data.content;
    },
  });
};
export const useSectionsQuery = () => {
  return useQuery({
    enabled: true,
    queryKey: ["sections"],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const response = await client.sectionsGet("668b2b68116dba6a2eade8b3");
      return response.data.content;
    },
  });
};
