/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

interface State {
  user: User | null;
  authToken: string | null;
}

const initialState: State = {
  user: null,
  authToken: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setAuthToken } = appSlice.actions;

export default appSlice.reducer;
