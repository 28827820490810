import React from "react";

interface Props {
  exercise: CourseExercise;
}

export default function VocabularyPairsExerciseData(props: Props) {
  const { exercise } = props;

  const words = exercise.vocabularyPairsExerciseData?.words ?? "";
  const translations = exercise.vocabularyPairsExerciseData?.translations ?? "";

  return (
    <div className="flex items-center">
      <div className="text-slate-300 text-xs pl-3">{words}</div>
      <div className="text-slate-500 text-xs pl-3">{translations}</div>
    </div>
  );
}
