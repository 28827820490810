import React from "react";

interface Props {
  exercise: CourseExercise;
}

export default function CompleteSentenceExerciseData(props: Props) {
  const { exercise } = props;

  const words = exercise.completeSentenceExerciseData?.words ?? "";
  const sentence = exercise.completeSentenceExerciseData?.sentence ?? "";
  const correctAnswer =
    exercise.completeSentenceExerciseData?.correctAnswer ?? "";

  return (
    <div className="flex items-center">
      <div className="text-slate-300 text-xs pl-3">{sentence}</div>
      <div className="text-slate-300 text-xs pl-3">{words}</div>
      <div className="text-slate-500 text-xs pl-3">{correctAnswer}</div>
    </div>
  );
}
