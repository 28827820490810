// @ts-nocheck
import './models';

const axios = require('axios');

let token = '';

let baseUrl = process.env.REACT_APP_BASE_URL ?? '';

const client = {

  setToken: (value) => { token = value; },
  setBaseUrl: (value) => { baseUrl = value; },

  /**
  * Create course progress
  * @param {PostCourseProgressRequest} body - request data
  * @returns {Promise<AxiosResponse<PostCourseProgressResponse>} undefined - Success result
  */
  courseProgressPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/course_progress`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Get Course Progress
  * @param {string| null } courseId - null
  * @returns {Promise<AxiosResponse<GetCourseProgressResponse>} undefined - Success result
  */
  courseProgressGet: async (courseId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/course_progress?courseId=${courseId}`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Edit Course progress
  * @param {string} courseProgressId - object Id
  * @param {PutCourseProgressRequest} body - request data
  * @returns {Promise<AxiosResponse<PutCourseProgressResponse>} undefined - Success result
  */
  courseProgressCourseProgressIdPut: async (courseProgressId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/course_progress/${courseProgressId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Delete CourseProgress
  * @param {string} courseProgressId - object Id
  * @returns {Promise<AxiosResponse<DeleteCourseProgressResponse>} undefined - Success result
  */
  courseProgressCourseProgressIdDelete: async (courseProgressId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/course_progress/${courseProgressId}`);
    return axios.delete(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Create course
  * @param {PostCoursesRequest} body - request data
  * @returns {Promise<AxiosResponse<PostCoursesResponse>} undefined - Success result
  */
  coursesPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/courses`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Get Courses
  * @returns {Promise<AxiosResponse<GetCoursesResponse>} undefined - Success result
  */
  coursesGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/courses`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get Course
  * @param {string} courseId - object Id
  * @returns {Promise<AxiosResponse<Course>} undefined - Success result
  */
  coursesCourseIdGet: async (courseId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/courses/${courseId}`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Edit Course
  * @param {string} courseId - object Id
  * @param {PutCoursesRequest} body - request data
  * @returns {Promise<AxiosResponse<PutCoursesResponse>} undefined - Success result
  */
  coursesCourseIdPut: async (courseId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/courses/${courseId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Delete Courses
  * @param {string} courseId - object Id
  * @returns {Promise<AxiosResponse<DeleteCoursesResponse>} undefined - Success result
  */
  coursesCourseIdDelete: async (courseId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/courses/${courseId}`);
    return axios.delete(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Create exercise
  * @param {PostExercisesRequest} body - request data
  * @returns {Promise<AxiosResponse<PostExercisesResponse>} undefined - Success result
  */
  exercisesPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/exercises`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Get Exercises
  * @param {string| null } lessonId - null
  * @returns {Promise<AxiosResponse<GetExercisesResponse>} undefined - Success result
  */
  exercisesGet: async (lessonId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/exercises?lessonId=${lessonId}`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Edit Exercise
  * @param {string} exerciseId - object Id
  * @param {PutExercisesRequest} body - request data
  * @returns {Promise<AxiosResponse<PutExercisesResponse>} undefined - Success result
  */
  exercisesExerciseIdPut: async (exerciseId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/exercises/${exerciseId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Delete Exercises
  * @param {string} exerciseId - object Id
  * @returns {Promise<AxiosResponse<DeleteExercisesResponse>} undefined - Success result
  */
  exercisesExerciseIdDelete: async (exerciseId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/exercises/${exerciseId}`);
    return axios.delete(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Edit Exercise
  * @param {PutExercisesSetIndexRequest} body - request data
  * @returns {Promise<AxiosResponse<PutExercisesSetIndexResponse>} undefined - Success result
  */
  exercisesSetIndexPut: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/exercises/set_index`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Create Lesson
  * @param {PostLessonsRequest} body - request data
  * @returns {Promise<AxiosResponse<PostLessonsResponse>} undefined - Success result
  */
  lessonsPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/lessons`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Get Lessons
  * @param {string| null } unitId - null
  * @returns {Promise<AxiosResponse<GetLessonssResponse>} undefined - Success result
  */
  lessonsGet: async (unitId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/lessons?unitId=${unitId}`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Edit Lesson
  * @param {string} lessonId - object Id
  * @param {PostLessonsRequest} body - request data
  * @returns {Promise<AxiosResponse<PutLessonsResponse>} undefined - Success result
  */
  lessonsLessonIdPut: async (lessonId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/lessons/${lessonId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Delete Lessons
  * @param {string} lessonId - object Id
  * @returns {Promise<AxiosResponse<DeleteLessonsResponse>} undefined - Success result
  */
  lessonsLessonIdDelete: async (lessonId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/lessons/${lessonId}`);
    return axios.delete(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Create Section
  * @param {PostSectionsRequest} body - request data
  * @returns {Promise<AxiosResponse<PostSectionsResponse>} undefined - Success result
  */
  sectionsPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/sections`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Get Sections
  * @param {string| null } courseId - null
  * @returns {Promise<AxiosResponse<GetSectionsResponse>} undefined - Success result
  */
  sectionsGet: async (courseId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/sections?courseId=${courseId}`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Edit Section
  * @param {string} sectionId - object Id
  * @param {PostSectionsRequest} body - request data
  * @returns {Promise<AxiosResponse<PutSectionsResponse>} undefined - Success result
  */
  sectionsSectionIdPut: async (sectionId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/sections/${sectionId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Delete Sections
  * @param {string} sectionId - object Id
  * @returns {Promise<AxiosResponse<DeleteSectionsResponse>} undefined - Success result
  */
  sectionsSectionIdDelete: async (sectionId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/sections/${sectionId}`);
    return axios.delete(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Login
  * @param {PostLoginRequest} body - request data
  * @returns {Promise<AxiosResponse<PostLoginResponse>} undefined - Success result
  */
  loginPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/login`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Register
  * @param {PostRegisterRequest} body - request data
  * @returns {Promise<AxiosResponse<PostRegisterResponse>} undefined - Success result
  */
  registerPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/register`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Logout
  * @returns {Promise<AxiosResponse<PostLogoutResponse>} undefined - Success result
  */
  logoutPost: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/logout`);
    return axios.post(encodedURI, {}, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get me
  * @returns {Promise<AxiosResponse<PostLogoutResponse>} undefined - Success result
  */
  getMeGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/get_me`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Create Unit
  * @param {PostUnitsRequest} body - request data
  * @returns {Promise<AxiosResponse<PostUnitsResponse>} undefined - Success result
  */
  unitsPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/units`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Get Units
  * @param {string| null } sectionId - null
  * @returns {Promise<AxiosResponse<GetUnitssResponse>} undefined - Success result
  */
  unitsGet: async (sectionId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/units?sectionId=${sectionId}`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Edit Unit
  * @param {string} unitId - object Id
  * @param {PostUnitsRequest} body - request data
  * @returns {Promise<AxiosResponse<PutUnitsResponse>} undefined - Success result
  */
  unitsUnitIdPut: async (unitId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/units/${unitId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Delete Units
  * @param {string} unitId - object Id
  * @returns {Promise<AxiosResponse<DeleteUnitsResponse>} undefined - Success result
  */
  unitsUnitIdDelete: async (unitId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/units/${unitId}`);
    return axios.delete(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
};
export default client;
