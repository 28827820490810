import React from "react";

interface Props {
  exercise: CourseExercise;
}

export default function BuildSentenceExerciseData(props: Props) {
  const { exercise } = props;

  const sentence = exercise.buildSentenceExerciseData?.sentence ?? "";
  const extraWords = exercise.buildSentenceExerciseData?.extraWords ?? "";

  return (
    <div className="flex items-center">
      <div className="text-slate-300 text-xs pl-3">{sentence}</div>
      <div className="text-slate-500 text-xs pl-3">{extraWords}</div>
    </div>
  );
}
