import * as React from "react";
import { useState, useCallback } from "react";

import { useMutation, useQueryClient } from "react-query";

import CourseUnit from "./course-unit";
import client from "../../../api/api";
import { useUnitsQuery } from "../../../hooks/query";

interface Props {
  section: CourseSection;
}
export default function CourseSection(props: Props) {
  const { section } = props;
  const { data: units } = useUnitsQuery(props.section._id ?? "");
  const [adding, setAdding] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [unitName, setUnitName] = useState("");
  const queryClient = useQueryClient();

  const createUnit = async (name: string) => {
    if (!section._id) throw new Error("Section id is not defined");
    const response = await client.unitsPost({ sectionId: section._id, name });
    return response.data.result;
  };

  const { mutate } = useMutation({
    mutationFn: createUnit,
    onSuccess: () => {
      queryClient.invalidateQueries("units");
      setAdding(false);
    },
  });

  const onUnitNameChanged = useCallback((val: string) => setUnitName(val), []);

  const onCreateUnit = useCallback(() => {
    mutate(unitName);
    setUnitName("");
  }, [mutate, unitName]);

  return (
    <div className="pl-4">
      <div
        className="bg-slate-50 hover:bg-sky-700 text-lg cursor-pointer flex items-center"
        onClick={() => setExpanded(!expanded)}
      >
        <div>{section.name}</div>
        <div className="pl-4 text-gray-400 text-sm">Sección</div>
      </div>
      {expanded && (
        <div>
          {units &&
            units.map((unit) => <CourseUnit unit={unit} key={unit._id} />)}
          {adding ? (
            <div className="flex pl-8 bg-red-50">
              <input
                onChange={(e) => onUnitNameChanged(e.target.value)}
                placeholder="Nombre de la unidad"
              />
              <div className="cursor-pointer" onClick={onCreateUnit}>
                OK
              </div>
              <div
                className="cursor-pointer pl-2"
                onClick={() => setAdding(false)}
              >
                Cancelar
              </div>
            </div>
          ) : (
            <div
              className="pb-3 pl-8 text-xs text-gray-700 cursor-pointer hover:text-sky-700"
              onClick={() => setAdding(true)}
            >
              Agregar unidad
            </div>
          )}
        </div>
      )}
    </div>
  );
}
