import { useCallback } from "react";

import {
  DeleteOutline,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import { useQueryClient } from "react-query";

import BuildSentenceExerciseData from "./components/build-sentence-exercise-data";
import CompleteSentenceExerciseData from "./components/complete-sentence-exercise-data";
import VocabularyExerciseData from "./components/vocabulary-exercise-data";
import VocabularyPairsExerciseData from "./components/vocabulary-pairs-exercise-data";
import client from "../../../../api/api";

interface Props {
  exercise: CourseExercise;
  onClick: () => void;
  isLast: boolean;
}

export default function CourseExercise(props: Props) {
  const { exercise, isLast, onClick } = props;
  const queryClient = useQueryClient();

  const changeIndex = useCallback(
    async (index: number) => {
      const response = await client.exercisesSetIndexPut({
        exerciseId: exercise._id ?? "",
        index,
      });
      if (response.status == 200) {
        queryClient.invalidateQueries(["exercises", exercise.lessonId]);
      }
    },
    [exercise, queryClient],
  );

  const deleteExercise = useCallback(async () => {
    if (!exercise._id) return;
    const response = await client.exercisesExerciseIdDelete(exercise._id);
    if (response.status == 200) {
      queryClient.invalidateQueries(["exercises", exercise.lessonId]);
    }
  }, [exercise, queryClient]);

  return (
    <div className="pl-8">
      <div className="flex items-center">
        <div className="pr-3">{exercise.type}</div>
        <div className="hover:bg-gray-100 cursor-pointer" onClick={onClick}>
          {exercise.type == "vocabulary" && (
            <VocabularyExerciseData exercise={exercise} />
          )}
          {exercise.type == "vocabularyPairs" && (
            <VocabularyPairsExerciseData exercise={exercise} />
          )}
          {exercise.type == "buildSentence" && (
            <BuildSentenceExerciseData exercise={exercise} />
          )}
          {exercise.type == "completeSentence" && (
            <CompleteSentenceExerciseData exercise={exercise} />
          )}
        </div>

        <div className="pl-3">
          <div className="flex items-center flex-direction-column ">
            {exercise.index !== 0 && (
              <div
                className="hover:bg-sky-200 cursor-pointer"
                onClick={() => changeIndex((exercise.index ?? 0) - 1)}
              >
                <KeyboardArrowUpRounded sx={{ height: 10 }} />
              </div>
            )}
            {!isLast && (
              <div
                className="hover:bg-sky-200 cursor-pointer"
                onClick={() => changeIndex((exercise.index ?? 0) + 1)}
              >
                <KeyboardArrowDownRounded sx={{ height: 10 }} />
              </div>
            )}
            <div
              className="hover:bg-red-200 cursor-pointer"
              onClick={() => deleteExercise()}
            >
              <DeleteOutline sx={{ height: 10, color: "grey" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
