import React, { useCallback, useState } from "react";

import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import CourseSection from "./components/course-section";
import client from "../../api/api";
import { useSectionsQuery } from "../../hooks/query";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { removeCookie } from "../../services/cookies";
import { setAuthToken, setUser } from "../../slices/app-slice";
import { RootState } from "../../store";

export default function DashboardPage() {
  const [adding, setAdding] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const { data: sections } = useSectionsQuery();
  const queryClient = useQueryClient();
  const user = useAppSelector((state: RootState) => state.app.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onLogoutClicked = async () => {
    await client.logoutPost();
    dispatch(setUser(null));
    dispatch(setAuthToken(null));
    removeCookie("authToken");
    navigate("/login");
    return;
  };

  const createSection = async (name: string) => {
    const response = await client.sectionsPost({
      name,
      courseId: "668b2b68116dba6a2eade8b3",
    });
    return response.data.result;
  };

  const { mutate } = useMutation({
    mutationFn: createSection,
    onSuccess: () => {
      queryClient.invalidateQueries("sections");
      setAdding(false);
    },
  });

  const onSectionNameChanged = useCallback(
    (val: string) => setSectionName(val),
    [],
  );

  const onCreateSection = useCallback(() => {
    mutate(sectionName);
    setSectionName("");
  }, [mutate, sectionName]);

  return (
    <div className="relative h-full">
      <div className="my-3">
        <div className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mx-1">
          {user?.email}
        </div>
        <button
          className="text-xs ring-inset ring-green-600/20 items-center rounded-md bg-gray-100 px-2 py-1"
          onClick={onLogoutClicked}
        >
          Log out
        </button>
      </div>
      <div className="text-lg pb-4">🇪🇸 Curso de español:</div>
      {sections &&
        sections.map((section) => (
          <CourseSection section={section} key={section._id} />
        ))}

      {adding ? (
        <div className="pl-8 flex bg-red-50">
          <input
            onChange={(e) => onSectionNameChanged(e.target.value)}
            placeholder="Nombre de la sección"
          />
          <div className="cursor-pointer pl-2" onClick={onCreateSection}>
            OK
          </div>
          <div className="cursor-pointer pl-2" onClick={() => setAdding(false)}>
            Cancelar
          </div>
        </div>
      ) : (
        <div
          className="cursor-pointer hover:text-sky-700"
          onClick={() => setAdding(true)}
        >
          Agregar sección
        </div>
      )}
    </div>
  );
}
