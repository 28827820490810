import * as React from "react";
import { useState } from "react";

import { Edit } from "@mui/icons-material";
import { Box } from "@mui/material";

import AddEditExerciseDialog from "./add-edit-exercise-dialog/add-edit-exercise-dialog";
import CourseExercise from "./course-exercise/course-exercise";
import { useExercisesQuery } from "../../../hooks/query";

const imagesBaseUrl = "https://api.dialogino.cz/lesson_icons";

interface Props {
  lesson: CourseLesson;
  onEditPressed: () => void;
}
export default function CourseLesson(props: Props) {
  const { lesson, onEditPressed } = props;
  const { data: exercises } = useExercisesQuery(lesson._id ?? "");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingExercise, setEditingExercise] = useState<CourseExercise>();
  const [expanded, setExpanded] = useState(false);

  function openAddEditDialog(exercise?: CourseExercise) {
    setEditingExercise(exercise);
    setDialogOpen(true);
  }
  function closeAddEditDialog() {
    setEditingExercise(undefined);
    setDialogOpen(false);
  }

  return (
    <div className="pl-8 ">
      {dialogOpen && (
        <div
          className="absolute bg-stone-500 top-0 left-0 w-full h-full"
          style={{ backgroundColor: "rgba(23,23,23,0.95)" }}
        >
          <div className="max-w-[70%] m-auto mt-10 bg-white">
            <AddEditExerciseDialog
              onClose={() => closeAddEditDialog()}
              exercise={editingExercise}
              lessonId={lesson._id ?? ""}
            />
          </div>
        </div>
      )}
      <div className="bg-slate-200 hover:bg-sky-200 flex items-center cursor-pointer">
        <div
          className="text-lg flex items-center"
          onClick={() => setExpanded(!expanded)}
        >
          <Box
            width={20}
            height={20}
            sx={{ background: "grey" }}
            border={"solid 1px black"}
            borderRadius={10}
            marginRight={"10px"}
          >
            {lesson.iconFileName && (
              <img
                src={`${imagesBaseUrl}/${lesson.iconFileName}.svg`}
                alt={lesson.iconFileName}
              />
            )}
          </Box>
          <div>{lesson.name}</div>
          <div className="pl-4 text-gray-400 text-sm">Lección</div>
        </div>
        <div onClick={onEditPressed}>
          <Edit sx={{ height: 15, color: "#D1D2D1" }} />
        </div>
      </div>
      {expanded && (
        <div>
          {exercises &&
            exercises
              .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
              .map((exercise) => (
                <CourseExercise
                  key={exercise._id}
                  exercise={exercise}
                  onClick={() => openAddEditDialog(exercise)}
                  isLast={exercise.index == exercises.length - 1}
                />
              ))}

          <div
            className="pb-3 pl-8 text-xs text-gray-700 cursor-pointer hover:text-sky-700"
            onClick={() => openAddEditDialog()}
          >
            Agregar ejercicio
          </div>
        </div>
      )}
    </div>
  );
}
