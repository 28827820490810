import React, { useCallback } from "react";

import { Container, FormControl, TextField } from "@mui/material";

interface Props {
  exerciseData?: BuildSentenceExerciseData;
  onChange: (value: BuildSentenceExerciseData) => void;
}
export default function BuildSentenceExerciseForm(props: Props) {
  const { exerciseData, onChange } = props;

  const setSentence = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        sentence: value,
      });
    },
    [exerciseData, onChange],
  );

  const setExtraWords = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        extraWords: value,
      });
    },
    [exerciseData, onChange],
  );

  return (
    <Container>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Frase"
          variant="outlined"
          placeholder="la flor es amarilla"
          value={exerciseData?.sentence}
          onChange={(e) => setSentence(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Palabras extra"
          placeholder="mano,perro,gato"
          variant="outlined"
          value={exerciseData?.extraWords}
          onChange={(e) => setExtraWords(e.target.value)}
        />
      </FormControl>
    </Container>
  );
}
