/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useCallback, useState } from "react";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";

import BuildSentenceExerciseForm from "./components/build-sentence-exercise-form";
import CompleteSentenceExerciseForm from "./components/complete-sentence-exercise-form";
import VocabularyExerciseForm from "./components/vocabulary-exercise-form";
import VocabularyPairsExerciseForm from "./components/vocabulary-pairs-exercise-form";
import client from "../../../../api/api";

interface Props {
  lessonId: string;
  exercise?: CourseExercise;
  onClose: () => void;
}

const exerciseTypes = [
  "vocabulary",
  "vocabularyPairs",
  "buildSentence",
  "completeSentence",
];
export default function AddEditExerciseDialog(props: Props) {
  const { exercise, lessonId, onClose } = props;
  const [type, setType] = useState<string | undefined>(
    exercise?.type ?? "vocabulary",
  );
  const [vocabularyData, setVocabularyData] = useState<
    VocabularyExerciseData | undefined
  >(
    exercise?.type == "vocabulary"
      ? exercise.vocabularyExerciseData
      : undefined,
  );
  const [vocabularyPairsData, setVocabularyPairsData] = useState<
    VocabularyPairsExerciseData | undefined
  >(
    exercise?.type == "vocabularyPairs"
      ? exercise.vocabularyPairsExerciseData
      : undefined,
  );
  const [buildSentenceData, setBuildSentenceData] = useState<
    BuildSentenceExerciseData | undefined
  >(
    exercise?.type == "buildSentence"
      ? exercise.buildSentenceExerciseData
      : undefined,
  );
  const [completeSentenceData, setCompleteSentenceData] = useState<
    CompleteSentenceExerciseData | undefined
  >(
    exercise?.type == "completeSentence"
      ? exercise.completeSentenceExerciseData
      : undefined,
  );

  const queryClient = useQueryClient();

  const saveExercise = async () => {
    if (!lessonId) throw new Error("Lesson id is not defined");
    let data: any = {};
    if (type === "vocabulary") {
      data = vocabularyData ?? {};
    } else if (type === "vocabularyPairs") {
      data = vocabularyPairsData ?? {};
    } else if (type === "buildSentence") {
      data = buildSentenceData ?? {};
    } else if (type === "completeSentence") {
      data = completeSentenceData ?? {};
    }

    if (exercise) {
      const response = await client.exercisesExerciseIdPut(`${exercise._id}`, {
        lessonId,
        type: type ?? "",
        index: exercise.index ?? 0,
        exerciseData: data,
      });
      return response.data.result;
    } else {
      const response = await client.exercisesPost({
        lessonId,
        type: type ?? "",
        exerciseData: data,
      });
      return response.data.result;
    }
  };

  const { mutate } = useMutation({
    mutationFn: saveExercise,
    onSuccess: () => {
      queryClient.invalidateQueries(["exercises", lessonId]);
      onClose();
    },
  });

  const onCreateExercise = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <Box>
      <DialogTitle variant="h4">
        {exercise ? "Editar Ejercicio" : "Nuevo ejercicio"}
      </DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel>{"type"}</InputLabel>
          <Select
            value={type}
            label={"type"}
            onChange={(e) => setType(e.target.value)}
          >
            {exerciseTypes.map((exerciseType: string) => (
              <MenuItem key={exerciseType} value={exerciseType}>
                {exerciseType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {type === "vocabulary" && (
          <VocabularyExerciseForm
            exerciseData={vocabularyData}
            onChange={setVocabularyData}
          />
        )}
        {type === "vocabularyPairs" && (
          <VocabularyPairsExerciseForm
            exerciseData={vocabularyPairsData}
            onChange={setVocabularyPairsData}
          />
        )}
        {type === "buildSentence" && (
          <BuildSentenceExerciseForm
            exerciseData={buildSentenceData}
            onChange={setBuildSentenceData}
          />
        )}
        {type === "completeSentence" && (
          <CompleteSentenceExerciseForm
            exerciseData={completeSentenceData}
            onChange={setCompleteSentenceData}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="error">
          cancelar
        </Button>
        <Button onClick={onCreateExercise}>Guardar</Button>
      </DialogActions>
    </Box>
  );
}
