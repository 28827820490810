import * as React from "react";
import { useState } from "react";

import AddEditLessonDialog from "./add-edit-lesson-dialog/add-edit-lesson-dialog";
import CourseLesson from "./course-lesson";
import { useLessonsQuery } from "../../../hooks/query";

interface Props {
  unit: CourseUnit;
}
export default function CourseUnit(props: Props) {
  const { unit } = props;
  const { data: lessons } = useLessonsQuery(props.unit._id ?? "");
  const [expanded, setExpanded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingLesson, setEditingLesson] = useState<CourseLesson>();

  function openAddEditDialog(lesson?: CourseLesson) {
    setEditingLesson(lesson);
    setDialogOpen(true);
  }
  function closeAddEditDialog() {
    setEditingLesson(undefined);
    setDialogOpen(false);
  }
  return (
    <div className="pl-8">
      {dialogOpen && (
        <div
          className="absolute bg-stone-500 top-0 left-0 w-full h-full"
          style={{ backgroundColor: "rgba(23,23,23,0.95)" }}
        >
          <div className="max-w-[70%] m-auto mt-10 bg-white">
            <AddEditLessonDialog
              lesson={editingLesson}
              onClose={() => closeAddEditDialog()}
              unitId={unit._id ?? ""}
            />
          </div>
        </div>
      )}
      <div
        className="bg-slate-100 hover:bg-sky-200 text-lg cursor-pointer flex items-center"
        onClick={() => setExpanded(!expanded)}
      >
        <div>{unit.name}</div>
        <div className="pl-4 text-gray-400 text-sm">Unidad</div>
      </div>
      {expanded && (
        <div>
          {lessons &&
            lessons.map((lesson) => (
              <CourseLesson
                lesson={lesson}
                key={lesson._id}
                onEditPressed={() => openAddEditDialog(lesson)}
              />
            ))}
          <div
            className="pb-3 pl-8 text-xs text-gray-700 cursor-pointer hover:text-sky-700"
            onClick={() => openAddEditDialog()}
          >
            Agregar lección
          </div>
        </div>
      )}
    </div>
  );
}
