import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import client from "../../api/api";
import { useAppSelector } from "../../hooks/redux";
import { setCookie } from "../../services/cookies";
import { setAuthToken } from "../../slices/app-slice";
import { RootState } from "../../store";

export default function LoginPage() {
  const authToken = useAppSelector((state: RootState) => state.app.authToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [loading, setloading] = useState<boolean>(false);

  async function handleSubmitClicked() {
    if (email && password) {
      setloading(true);

      const response = await client.loginPost({
        email: email ?? "",
        password: password ?? "",
      });

      setloading(false);

      if (response.status === 200) {
        const authToken = response.data.auth_token;
        client.setToken(authToken);
        setCookie("authToken", authToken ?? "");
        dispatch(setAuthToken(authToken));
      } else if (response.response.status === 503) {
        navigate("/service_unavailable");
      }
    }
  }

  useEffect(() => {
    if (authToken) {
      setTimeout(() => navigate("/dashboard"), 1500);
    }
  }, [authToken, navigate]);

  return (
    <div className="">
      <div>Login</div>
      <input
        type="email"
        placeholder="Email"
        onChange={(v) => setEmail(v.target.value)}
      />
      <input
        type="password"
        placeholder="Passsword"
        onChange={(v) => setPassword(v.target.value)}
      />
      {loading ? (
        <div>Loading</div>
      ) : (
        <button className="btn btn-primary" onClick={handleSubmitClicked}>
          Login
        </button>
      )}
    </div>
  );
}
