import React, { useCallback, useEffect, useState } from "react";

import { Routes, Route } from "react-router-dom";

import client from "./api/api";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import DashboardPage from "./pages/dashboard/dashboard-page";
import LoginPage from "./pages/login/login-page";
import NotFoundPage from "./pages/not-found/not-found-page";
import SplashPage from "./pages/splash/splash-page";
import { getCookie, removeCookie } from "./services/cookies";
import { setAuthToken, setUser } from "./slices/app-slice";
import { RootState } from "./store";

export default function App() {
  const dispatch = useAppDispatch();
  const authToken = useAppSelector((state: RootState) => state.app.authToken);
  const [loading, setLoading] = useState(true);

  const cookieAuthToken = getCookie("authToken");

  const checkAuthToken = useCallback(async () => {
    if (authToken) {
      client.setToken(authToken);
      const response = await client.getMeGet();

      if (response.status == 200) {
        const user = response.data;
        dispatch(setUser(user));
      } else {
        removeCookie("authToken");
        dispatch(setAuthToken(null));
      }
    }
    setLoading(false);
  }, [authToken, dispatch]);

  useEffect(() => {
    checkAuthToken();
  }, [checkAuthToken]);

  useEffect(() => {
    dispatch(setAuthToken(cookieAuthToken));
  }, [dispatch, cookieAuthToken]);

  if (loading) return <h1>Loading</h1>;

  return (
    <Routes>
      <Route path="/" element={<SplashPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
