import React, { useCallback } from "react";

import { Container, FormControl, TextField } from "@mui/material";

interface Props {
  exerciseData?: VocabularyExerciseData;
  onChange: (value: VocabularyExerciseData) => void;
}
export default function VocabularyExerciseForm(props: Props) {
  const { exerciseData, onChange } = props;

  const setImageName = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        imageFileName: value,
      });
    },
    [exerciseData, onChange],
  );

  const setImageLabel = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        imageLabel: value,
      });
    },
    [exerciseData, onChange],
  );

  const setCorrectAnswers = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        correctAnswers: value,
      });
    },
    [exerciseData, onChange],
  );

  const setWrongAnswers = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        wrongAnswers: value,
      });
    },
    [exerciseData, onChange],
  );

  return (
    <Container>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Nombre de la imagen"
          placeholder="flower"
          variant="outlined"
          value={exerciseData?.imageFileName}
          onChange={(e) => setImageName(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Texto de la imagen"
          placeholder="Květina"
          variant="outlined"
          value={exerciseData?.imageLabel}
          onChange={(e) => setImageLabel(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Respuesta correcta"
          placeholder="La flor"
          variant="outlined"
          value={exerciseData?.correctAnswers}
          onChange={(e) => setCorrectAnswers(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Respuestas incorrectas"
          placeholder="La flor, El perro, El gato"
          variant="outlined"
          value={exerciseData?.wrongAnswers}
          onChange={(e) => setWrongAnswers(e.target.value)}
        />
      </FormControl>
    </Container>
  );
}
