import React, { useCallback } from "react";

import { Container, FormControl, TextField } from "@mui/material";

interface Props {
  exerciseData?: CompleteSentenceExerciseData;
  onChange: (value: CompleteSentenceExerciseData) => void;
}
export default function CompleteSentenceExerciseForm(props: Props) {
  const { exerciseData, onChange } = props;

  const setSentence = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        sentence: value,
      });
    },
    [exerciseData, onChange],
  );
  const setWords = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        words: value,
      });
    },
    [exerciseData, onChange],
  );

  const setCorrectAnswer = useCallback(
    (value: string) => {
      onChange({
        ...exerciseData,
        correctAnswer: value,
      });
    },
    [exerciseData, onChange],
  );

  return (
    <Container>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Frase"
          placeholder="la * es amarilla"
          variant="outlined"
          value={exerciseData?.sentence}
          onChange={(e) => setSentence(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Palabras"
          placeholder="flor,mano,perro,gato"
          variant="outlined"
          value={exerciseData?.words}
          onChange={(e) => setWords(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          id="outlined-basic"
          label="Respuesta correcta"
          placeholder="flor"
          variant="outlined"
          value={exerciseData?.correctAnswer}
          onChange={(e) => setCorrectAnswer(e.target.value)}
        />
      </FormControl>
    </Container>
  );
}
