import React, { useCallback } from "react";

import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";

interface Props {
  notes: CoursenNote[];
  onChange: (notes: CoursenNote[]) => void;
}
export default function LessonNotesConfiguration(props: Props) {
  // const imagesBaseUrl = "http://localhost:3300/images/lesson_notes";
  const imagesBaseUrl = "https://api.dialogino.cz/lesson_notes";

  const { notes, onChange } = props;
  const notesSorted = notes.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));

  const addNote = useCallback(() => {
    const newNotes = [...notes];
    newNotes.push({ index: notes.length });
    onChange(newNotes);
  }, [notes, onChange]);

  const setImageName = useCallback(
    (index: number, imageName: string) => {
      const newNotes = [...notes];
      newNotes[index].imageFileName = imageName;
      onChange(newNotes);
    },
    [notes, onChange],
  );
  const setIndex = useCallback(
    (index: number, newIndex: number) => {
      if (newIndex < 0 || newIndex >= notes.length) return;
      const newNotes = [...notes];
      const origin = newNotes[index];
      const destination = newNotes[newIndex];
      origin.index = newIndex;
      destination.index = index;

      onChange(newNotes);
    },
    [notes, onChange],
  );
  return (
    <Container sx={{ margin: "0" }}>
      <Typography variant="subtitle1">Notas</Typography>
      {notesSorted.map((note) => {
        return (
          <Container
            key={`${note.index}`}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "5px",
                backgroundColor: "white",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                width: "175px",
                height: "280px",
                margin: "10px",
                paddingLeft: "0",
              }}
            >
              {note.imageFileName && (
                <img
                  src={`${imagesBaseUrl}/${note.imageFileName}.svg`}
                  alt={note.imageFileName}
                />
              )}
            </Box>

            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <TextField
                id="outlined-basic"
                label="nombre de la imagen"
                variant="outlined"
                value={note.imageFileName}
                onChange={(e) => setImageName(note.index ?? 0, e.target.value)}
              />
            </FormControl>
            <Container
              sx={{
                margin: "0",
                display: "flex",
                flexDirection: "column",
                width: "50px",
              }}
            >
              <Button
                onClick={() => setIndex(note.index ?? 0, (note.index ?? 0) - 1)}
              >
                <ArrowUpward />
              </Button>
              <Button
                onClick={() => setIndex(note.index ?? 0, (note.index ?? 0) + 1)}
              >
                <ArrowDownward />
              </Button>
            </Container>
          </Container>
        );
      })}
      <Button onClick={addNote}>Agregar nota</Button>
    </Container>
  );
}
